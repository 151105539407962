import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import LinkBlank from '../../icons/ico-link-blank.svg';

import {
  getDesktopCashOrderUrl,
  getDesktopMarginOrderUrl,
  getDesktopMarginRefundUrl,
  getOddLotOrderUrl,
  getDesktopSmallLotOrderUrl,
  getUseqTradingUrl
} from '../../utils/formatter';

import { postUserTrade } from '../../utils/userMetrics';
import { trackNavigation } from '../../utils/analytics';
import navigationTypes from '../../actions/_navigationTypes';
import { useSelector } from 'react-redux';
import { isJpeq } from '../../utils/symbol';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import { useFeatureToggle } from '../../utils/hooks/useFeatureToggle';

const commonButtonStyle = {
  width: '75px',
  padding: '2px 0px 2px 0px',
  marginRight: '10px',
  marginBottom: '8px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '18px',
  verticalAlign: 'middle',
  display: 'inline-block',
  boxShadow: '0 1px 3px rgb(0 0 0 / 25%)',
  '-webkit-box-sizing': 'border-box',
  borderRadius: '2px',
  cursor: 'pointer',
  color: '#fff',
  '&:hover': {
    color: '#fff'
  },
  '&:focus': {
    opacity: '0.8'
  },
  whiteSpace: 'nowrap',
  '-webkit-touch-callout': 'none' /* iOS Safari */,
  '-webkit-user-select': 'none' /* Safari */,
  '-khtml-user-select': 'none' /* Konqueror HTML */,
  '-moz-user-select': 'none' /* Old versions of Firefox */,
  '-ms-user-select': 'none' /* Internet Explorer/Edge */,
  'user-select': 'none'
};

const useStyles = makeStyles({
  tradeButtons: {
    whiteSpace: 'nowrap'
  },
  marginOrder: {
    ...commonButtonStyle,
    border: '2px solid #dbab00',
    color: '#dbab00',
    backgroundColor: 'white',
    padding: '0px 0px 1px',
    '&:hover': {
      color: '#dbab00'
    }
  },
  cashSell: {
    ...commonButtonStyle,
    backgroundColor: '#5A84AF'
  },
  cashBuy: {
    ...commonButtonStyle,
    backgroundColor: '#D05A5A'
  },
  marginSell: {
    ...commonButtonStyle,
    backgroundColor: '#dbab00'
  },
  smallLotOrder: {
    ...commonButtonStyle,
    backgroundColor: '#d84e4e',
    marginRight: '6px'
  },
  oddLotOrder: {
    ...commonButtonStyle,
    backgroundColor: '#d84e4e',
    marginRight: '6px',
    paddingLeft: '16px',
    textAlign: 'left',
    background: `#ffffff url(${LinkBlank}) no-repeat 45px center`,
    width: '72px',
    backgroundSize: '12px'
  }
});

const TradeButtons = ({ symbol, isUseq }) => {
  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const classes = useStyles();

  const user = useSelector((state) => state.user || {});
  const metadata = useSelector((state) => state.symbols.data || { data: {} } || {});
  const symbolData = (metadata[symbol] || {}).data;
  const isJpeqSymbol = isJpeq((symbolData || {}).assetClass);
  const isDesktopWidth = useDesktopWidth();
  const isOddLotTradingEnabled = useFeatureToggle('oddLotTradingPopsicleEnabled');

  let buttonClick = async (targetUrl, navigationType, newTab) => {
    trackNavigation(navigationType);

    let data = {
      navigationType: navigationType,
      origin: 'MarketboardPro',
      symbol: symbol,
      redirectUrl: targetUrl,
      encryptedId: user.encryptedId
    };

    // Since the redirect can be faster than the browser sending the metric to loggly,
    // we will need to wait for it. However, sending the metric should take less than
    // a second to send.
    await Promise.race([
      postUserTrade(data),
      new Promise((resolve) => setTimeout(resolve, 400))
    ]);

    if (newTab) {
      window.open(targetUrl, '_blank');
      return;
    }

    window.location.href = targetUrl;
  };

  // Not even sure if we need this bit as we do the
  // conditional formatting below with "symbol &&"
  // However, I am leaving it here just in case
  if (!symbol) {
    return null;
  }

  const oddLotTradingButton = isOddLotTradingEnabled ? (
    <div
      className={classes.oddLotOrder}
      tabIndex="1"
      onClick={() =>
        buttonClick(
          getOddLotOrderUrl(user, symbol),
          navigationTypes.MP_ODD_LOT_ORDER_CLICK,
          true
        )
      }
    >
      {localize('symbol.details.button.odd.lot.order')}
    </div>
  ) : null;

  return (
    <>
      {symbol && (
        <div className={classes.tradeButtons}>
          {isJpeqSymbol && (
            <>
              <div
                className={classes.cashBuy}
                tabIndex="1"
                onClick={() =>
                  buttonClick(
                    getDesktopCashOrderUrl(user, symbol, 'buy'),
                    navigationTypes.MP_CASH_BUY_CLICK
                  )
                }
              >
                {localize('symbol.details.button.cash.buy')}
              </div>
              <div
                className={classes.cashSell}
                tabIndex="1"
                onClick={() =>
                  buttonClick(
                    getDesktopCashOrderUrl(user, symbol, 'sell'),
                    navigationTypes.MP_CASH_SELL_CLICK
                  )
                }
              >
                {localize('symbol.details.button.cash.sell')}
              </div>
              {user.isMarginEnabled && (
                <>
                  <div
                    className={classes.marginOrder}
                    tabIndex="1"
                    onClick={() =>
                      buttonClick(
                        getDesktopMarginOrderUrl(user, symbol),
                        navigationTypes.MP_MARGIN_ORDER_CLICK
                      )
                    }
                  >
                    {localize('symbol.details.button.margin.buy')}
                  </div>
                  <div
                    className={classes.marginSell}
                    tabIndex="1"
                    onClick={() =>
                      buttonClick(
                        getDesktopMarginRefundUrl(user, symbol),
                        navigationTypes.MP_MARGIN_SELL_CLICK
                      )
                    }
                  >
                    {localize('symbol.details.button.margin.refund')}
                  </div>
                </>
              )}
              <div
                className={classes.smallLotOrder}
                tabIndex="1"
                onClick={() =>
                  buttonClick(
                    getDesktopSmallLotOrderUrl(user, symbol),
                    navigationTypes.MP_SMALL_LOT_ORDER_CLICK
                  )
                }
              >
                {localize('symbol.details.button.small.lot.order')}
              </div>
              {oddLotTradingButton}
            </>
          )}
          {isUseq && (
            <>
              <div
                className={classes.cashBuy}
                tabIndex="1"
                onClick={() =>
                  buttonClick(
                    getUseqTradingUrl(symbol, 'buy', isDesktopWidth),
                    navigationTypes.MP_CASH_BUY_CLICK
                  )
                }
              >
                {localize('symbol.details.button.cash.buy')}
              </div>
              <div
                className={classes.cashSell}
                tabIndex="1"
                onClick={() =>
                  buttonClick(
                    getUseqTradingUrl(symbol, 'sell', isDesktopWidth),
                    navigationTypes.MP_CASH_SELL_CLICK
                  )
                }
              >
                {localize('symbol.details.button.cash.sell')}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TradeButtons;
