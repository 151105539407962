var navigationTypes = [
  // From Marketboard
  'MB_OPEN_SYMBOL_DETAILS',
  'MB_CASH_BUY_CLICK',
  'MB_CASH_SELL_CLICK',
  'MB_MARGIN_ORDER_CLICK',
  'MB_MARGIN_SELL_CLICK',

  // From Marketboard Pro
  'MP_SMALL_LOT_ORDER_CLICK',
  'MP_ODD_LOT_ORDER_CLICK',
  'MP_MARGIN_SELL_CLICK',
  'MP_MARGIN_ORDER_CLICK',
  'MP_CASH_SELL_CLICK',
  'MP_CASH_BUY_CLICK',
  'MP_MATRIX_CASH_SELL_CLICK',
  'MP_MATRIX_CASH_BUY_CLICK',

  // From Symbol Details
  'SD_OPEN_PC_SYMBOL_DETAILS',
  'SD_SMALL_LOT_ORDER_CLICK',
  'SD_ODD_LOT_ORDER_CLICK',
  'SD_MARGIN_SELL_CLICK',
  'SD_MARGIN_ORDER_CLICK',
  'SD_CASH_SELL_CLICK',
  'SD_CASH_BUY_CLICK',
  'SD_SHAREHOLDERS_INCENTIVES_CLICK',
  'SD_ANALYST_REPORT_CLICK',
  'SD_FINANCIAL_REPORT_CLICK',
  'SD_USEQ_FINANCIAL_REPORT_CLICK',
  'SD_SHIKIHO_REPORT_CLICK',
  'SD_MATRIX_CASH_SELL_CLICK',
  'SD_MATRIX_CASH_BUY_CLICK',

  // From Symbol Search
  'SS_CASH_BUY_CLICK'
];

/*
  Return a frozen object mapping all of the above action types
  to a property that has the same value. e.g.,
  
  'CONNECTING' => { CONNECTING: 'CONNECTING' }

  The purpose of this function is to avoid affectively entering the same
  string twice simply to export a constant.
*/
function constants(navigationTypes) {
  return Object.freeze(
    navigationTypes.reduce((obj, tar) => {
      return {
        ...obj,
        [tar]: tar,
      };
    }, {})
  );
}

export default constants(navigationTypes);
