// Monex Nkso Url Builder functions

import { isJapaneseEquity } from '../symbol';
import Logger from '../logger';
import { symbolExchange, symbolRoot } from '../formatter';

const formatSymbol = (symbolCode) => '00' + symbolCode.trim().padEnd(8, '0');
const formatValidity = (validity, orderType, marginAction) =>
  orderType === 'cash' || marginAction === 'open'
    ? `&yukoSiteDate=${validity}`
    : `&yukokikan=${validity}`;
const formatQuantity = (quantity) =>
  !quantity || quantity <= 0 ? '' : `&suryo=${quantity}`;
const formatPrice = (price) =>
  !price || price <= 0 ? '&nariSasiKbn=2' : `&nariSasiKbn=1&kakaku=${price}`;
const getOrderAction = (action) =>
  action === 'buy' ? 'kbodr/kai_odr' : 'sisan/odr_zan/uri';

// Buy Example
// https://dev.monex.co.jp/StockOrderManagement/ENCRYPTED_USER_ID/kbodr/kai_odr/hyji?specifyMeig=1&meigCd=0072030000&sijo=1&nariSasiKbn=2

// Sell Example
// https://dev.monex.co.jp/StockOrderManagement/ENCRYPTED_USER_ID/sisan/odr_zan/uri/hyji?specifyMeig=1&meigCd=0072030000&sijo=1&menuId=M500&nariSasiKbn=2

export function getNksolCashOrderUrl(
  dm,
  userKey,
  symbolId,
  action = 'buy',
  validity = null,
  quantity = null,
  price = null
) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }

  const exchangeId = symbolExchange(symbolId);
  if (!exchangeId) {
    Logger.error(
      `getNksolCashOrderUrl: Cannot generate url as exchange for symbol [${symbolId}] not found`
    );
    return '';
  }

  const symbol = formatSymbol(symbolRoot(symbolId));
  const actionString = getOrderAction(action);

  const menuIdQueryParam = action === 'sell' ? '&menuId=M500' : '';
  const validityQueryParam = validity ? formatValidity(validity, 'cash') : '';
  const quantityQueryParam = formatQuantity(quantity);
  const priceQueryParam = formatPrice(price);

  return (
    `https://${dm}.monex.co.jp/StockOrderManagement/${userKey}/${actionString}/hyji?specifyMeig=1&meigCd=${symbol}&sijo=${exchangeId}` +
    menuIdQueryParam +
    validityQueryParam +
    quantityQueryParam +
    priceQueryParam
  );
}

// New Margin Example
// https://dev.monex.co.jp/OdrMng/ENCRYPTED_USER_ID/sinyo/kbnsti/hyji?meigCd=0072030000&sijo=1&menuId=M1400

// Refund Margin Example
// https://dev.monex.co.jp/OdrMng/ENCRYPTED_USER_ID/sinyo/kbnsti/hyji?meigCd=0072030000&sijo=1&menuId=M1400

export function getNksolDefaultMarginOrderUrl(dm, userKey, symbolId) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }

  const symbol = formatSymbol(symbolRoot(symbolId));
  const exchangeId = symbolExchange(symbolId);
  return `https://${dm}.monex.co.jp/OdrMng/${userKey}/sinyo/kbnsti/hyji?meigCd=${symbol}&sijo=${exchangeId}&menuId=M1400`;
}

export function getNksolDefaultMarginRefundUrl(dm, userKey, symbolId) {
  return getNksolDefaultMarginOrderUrl(dm, userKey, symbolId);
}

// One Lot Order Example
// https://dev.monex.co.jp/StockOrderManagement/ENCRYPTED_USER_ID/hakabu/kai_odr/hyji?specifyMeig=1&meigCd=0072030000&sijo=1

export function getNksolSingleShareOrderUrl(dm, userKey, symbolId) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }

  const symbol = formatSymbol(symbolRoot(symbolId));
  const exchangeId = symbolExchange(symbolId);
  return `https://${dm}.monex.co.jp/StockOrderManagement/${userKey}/hakabu/kai_odr/hyji?specifyMeig=1&meigCd=${symbol}&sijo=${exchangeId}`;
}
