import React from 'react';
import { debounce } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import matrix from '../../utils/matrix';
import { useIntl } from 'react-intl';
import Number from '../common/Number';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { BackgroundFlash } from '../common/BackgroundFlash';
import { getDesktopCashOrderUrl } from '../../utils/formatter';
import { trackNavigation } from '../../utils/analytics';
import navigationTypes from '../../actions/_navigationTypes';
import makeStyles from '@mui/styles/makeStyles';
import { getSymbolClose } from '../../utils/symbol';
import { postUserTrade } from '../../utils/userMetrics';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import useActions from '../../utils/hooks/useActions';
import { start, stop } from '../../actions/stream';
import { selectChannel } from '../../selectors/channelSelectors';

const UPDATE_MATRIX_DEBOUNCE_MIN_TIME = 50;
const UPDATE_MATRIX_DEBOUNCE_MAX_TIME = 200;

const useStyles = makeStyles(theme => {
  let result = {
    expand: {
      transform: 'rotate(0deg)',
      boxShadow: `1px 1px 5px 0px #9E9E9E`,
    },
    expandOpen: {
      boxShadow: `1px 1px 5px 0px #9E9E9E`
    },
    expandOpenIcon: {
      transform: 'rotate(180deg)'
    }
  }

  // When doing unit tests, it was noticed that `theme` is an
  // empty object. For that reason, we execute this logic in
  // case we do have this property set.
  if (theme.transitions) {
    result.expand.transition = theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    });
  }
  return result;
});

function SymbolMatrixFlag({ row, type }) {
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  if (!row || !row.showFlag) {
    return null;
  }

  const flag = row[type + 'Flag'];
  if (!flag) {
    return null;
  }

  return (
    <span className="flag">
      {localize('symbol.matrix.flag.' + flag)}
    </span>
  );
}

const markFlags = (matrix) => {
  let maxBid = -1;
  let bidRow = null;
  let minAsk = -1;
  let askRow = null;

  for (let i = 0; i < matrix.rows.length; i++) {
    let row = matrix.rows[i];
    if (row.bid > 0 && (maxBid === -1 || row.price > maxBid)) {
      maxBid = row.price;
      bidRow = row;
      continue;
    }

    if (row.ask > 0 && (minAsk === -1 || row.price < minAsk)) {
      minAsk = row.price;
      askRow = row;
      continue;
    }
  }

  if (bidRow) {
    bidRow.showFlag = true;
  }

  if (askRow) {
    askRow.showFlag = true;
  }
}

function SymbolMatrix({ symbol, enabled }) {
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const { width } = useWindowDimensions();
  const isDesktopWidth = useDesktopWidth();
  const classes = useStyles();

  const user = useSelector((state) => state.user || {});
  const metadata = useSelector(
    (state) => state.symbols.data || { data: {} } || {}
  );

  const symbolMetadata = (metadata[symbol] || {}).data || {};
  const redirectToOrderPage = useCallback(async(type, price) => {
    if (!price) {
      return;
    }

    const quantity = symbolMetadata.lotSize || 1;
    const validity = type === 'buy' ? 1 : null;
    let redirectUrl = getDesktopCashOrderUrl(user, symbol, type, validity, quantity, price);

    const page = (window.location.hash || '').indexOf('/symbol/') === -1 ? 'MP' : 'SD';
    const navigationType = navigationTypes[`${page}_MATRIX_CASH_${type.toUpperCase()}_CLICK`];
    trackNavigation(navigationType);
    
    let data = {
      navigationType: navigationType,
      origin: page === 'MP' ? 'Marketboard' : 'SymbolDetails',
      symbol: symbol,
      redirectUrl: redirectUrl,
      encryptedId: user.encryptedId
    };

    await Promise.race([
      postUserTrade(data),
      new Promise((resolve) => setTimeout(resolve, 400))
    ]);
    window.location.href = redirectUrl;
  }, [user, symbolMetadata, symbol]);

  const formatRows = (rows, precision) => {
    return rows.map((row, index) => {
      if (!!row) {
        let className = '';
        if (row.atLast) {
          className = ' current';
        } else if (row.aboveLast) {
          className = ' above-last';
        } else if (row.belowLast) {
          className = ' below-last';
        }

        return (
          <div className="matrix-row" key={'matrix-row-' + index}>
            <div className={"ask allow-buy"} onClick={() => redirectToOrderPage('buy', row.price)}>
              <SymbolMatrixFlag row={row} type={'ask'}/>
              <BackgroundFlash inValue={row.ask}>
                <Number
                  value={row.ask}
                  hideWithoutEmptyIfZero={true}
                  className="hidden"
                  precision={precision}
                />
              </BackgroundFlash>
            </div>
            <div className={'price' + className}>
              <Number
                value={row.price}
                hideIfZero={true}
                className="hidden"
                precision={precision}
              />
            </div>
            <div className={"bid allow-sell"} onClick={() => redirectToOrderPage('sell', row.price)}>
              <SymbolMatrixFlag row={row} type={'bid'}/>
              <BackgroundFlash inValue={row.bid}>
                <Number
                  value={row.bid}
                  hideWithoutEmptyIfZero={true}
                  className="hidden"
                  precision={precision}
                />
              </BackgroundFlash>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const initState = matrix.createBlank();
  const [maxNumberOfRows, setMaxNumberOfRows] = useState(10);
  const [formattedMatrixData, setFormattedMatrixData] = useState(initState);
  const [unexpandable, setUnexpandable] = useState(width && width >= 601);
  const [expanded, setExpanded] = useState(unexpandable);
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);

  const startAllStreams = useCallback(() => {
    startStream(`${symbol}/matrix`);
    startStream(`${symbol}/miniquote`);
  }, [startStream, symbol]);

  const stopAllStreams = useCallback(() => {
    stopStream(`${symbol}/matrix`);
    stopStream(`${symbol}/miniquote`);
  }, [stopStream, symbol]);

  const matrixData = useSelector(
    (state) => selectChannel(state, symbol + '/matrix')
  );

  const channel = useMemo(() => `${symbol}/miniquote`, [symbol]);
  const miniquote = useSelector((state) => selectChannel(state, channel));
  const close = getSymbolClose(symbolMetadata.assetClass, miniquote);
  const precision = symbolMetadata.precision || 0;

  useEffect(() => {
    if (!enabled) {
      // do nothing
      return;
    }

    startAllStreams();

    return () => {
      stopAllStreams();
    };
  }, [symbol, startAllStreams, stopAllStreams, enabled]);

  const markLastPrice = useCallback(
    (matrix, close) => {
      if (close <= 0) {
        return;
      }

      let i, row, eqRow, gtRow, ltRow;
      for (i = 0; i < matrix.rows.length; i++) {
        row = matrix.rows[i];
        row._delta = row.price - close;
        if (row._delta === 0) {
          eqRow = row;
          break;
        } else if (row._delta > 0 &&
          (gtRow === undefined || row._delta < gtRow._delta)) {
          gtRow = row;
        } else if (row._delta < 0 &&
          (ltRow === undefined || row._delta > ltRow._delta)) {
          ltRow = row;
        }
      }
      if (eqRow) {
        eqRow.atLast = true;
      } else {
        if (gtRow) {
          gtRow.aboveLast = true;
        }
        if (ltRow) {
          ltRow.belowLast = true;
        }
      }
      for (i = 0; i < matrix.rows.length; i++) {
        delete matrix.rows[i]._delta;
      }
    },
    []
  );

  const updateMatrix = useCallback(
    (matrix, close) => {
      markLastPrice(matrix, close);
      markFlags(matrix);
      setFormattedMatrixData(matrix);
    },
    [markLastPrice, setFormattedMatrixData]
  );

  const debouncedUpdateMatrix = useMemo(
    () => debounce(updateMatrix, UPDATE_MATRIX_DEBOUNCE_MIN_TIME, { leading: true, maxWait: UPDATE_MATRIX_DEBOUNCE_MAX_TIME }),
    [updateMatrix]
  );

  useEffect(() => {
    let isUnexpandable = width && width >= 601;
    setUnexpandable(isUnexpandable);
    if (isUnexpandable) {
      setExpanded(true);
    }

    let maxRows = width && width >= 601 && width <= 800 ? 8 : 10;
    setMaxNumberOfRows(maxRows);
  }, [width]);

  //Use effect for changing number of rows
  useEffect(() => {
    var m = matrix.fromSnapshot(matrixData);
    if (!expanded && m.rows.length > 5) {
      matrix.trim(m, 5);
    } else if (maxNumberOfRows) {
      matrix.trim(m, maxNumberOfRows);
    }
    
    debouncedUpdateMatrix(m, close);
  }, [expanded, matrixData.when, maxNumberOfRows, unexpandable, debouncedUpdateMatrix, close]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="matrix-rows">
      <div className="matrix-row pre-order">
          <div className="ask">
            <BackgroundFlash inValue={formattedMatrixData.market.ask}>
              {formattedMatrixData.market.ask !== 0 &&
                <Number
                  value={formattedMatrixData.market.ask}
                  hideIfZero={true}
                  precision={precision}
                />
              }
            </BackgroundFlash>
          </div>
          <div className="price">成行注文</div>
          <div className="bid">
            <BackgroundFlash inValue={formattedMatrixData.market.bid}>
              {formattedMatrixData.market.bid !== 0 &&
                <Number
                  value={formattedMatrixData.market.bid}
                  hideIfZero={true}
                  precision={precision}
                />
              }
            </BackgroundFlash>
          </div>
        </div>
        <div className="matrix-row title">
          <div className="ask">
            {localize('symbol.details.matrix.ask.title')}
          </div>
          <div className="price">
            {localize('symbol.details.matrix.price.title')}
          </div>
          <div className="bid">
            {localize('symbol.details.matrix.bid.title')}
          </div>
        </div>
        <div className="matrix-row">
          <div className={isDesktopWidth ? 'ask allow-buy' : 'ask'}>
            <BackgroundFlash inValue={formattedMatrixData.over.ask}>
              <Number
                value={formattedMatrixData.over.ask}
                hideIfZero={true}
                precision={precision}
              />
            </BackgroundFlash>
          </div>
          <div className="price">OVER</div>
          <div className="bid" />
        </div>
        {formatRows(
          formattedMatrixData.rows,
          precision
        )}
        <div className="matrix-row">
          <div className="ask"> </div>
          <div className="price">UNDER</div>
          <div className={isDesktopWidth ? 'bid allow-sell' : 'bid'}>
            <BackgroundFlash inValue={formattedMatrixData.under.bid}>
              <Number
                value={formattedMatrixData.under.bid}
                hideIfZero={true}
                precision={precision}
              />
            </BackgroundFlash>
          </div>
        </div>
      </div>

      {!unexpandable && (
        <div className="expand-button">
          <IconButton
            className={expanded ? classes.expandOpen : classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            size="large"
          >
            <ExpandMoreIcon
              className={expanded ? classes.expandOpenIcon : ''}
            />
          </IconButton>
        </div>
      )}
    </>
  );
}

export default SymbolMatrix;
